import React from 'react';
import App from '../components/App';
import SEO from '../components/SEO';
import HeroInterior from '../components/HeroInterior';
import Container from '../components/Container';
import Button from '../components/Button';

export default function NotFound() {
  return (
    <App>
      <SEO title="Resource Not Found" />
      <HeroInterior
        version="blue"
        title="Page Not Found"
      />
      <div style={{ marginTop: '-5vh', textAlign: 'center' }}>
        <Container
          small
          border
          style={{
            background: '#FFF',
            maxWidth: '92%',
          }}
        >
          <p>{`The page you are looking for doesn't exist. Please return home to continue browsing.`}</p>
          <Button to="/">
            {'Return Home'}
          </Button>
        </Container>
      </div>
    </App>
  );
}
